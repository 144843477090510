





































import { Component, Vue } from 'vue-property-decorator';
import ModalEditProduct from '@/components/Products/ModalEditProduct.vue';
import ModalDeleteProduct from '@/components/Products/ModalDeleteProduct.vue';

import ProductModel from '@/models/Product.model';
import productsRepository from '@/repository/modules/products.repository';

@Component({
  components: {
    ModalEditProduct,
    ModalDeleteProduct,
  },
})
export default class ListProducts extends Vue {
  page = 1;
  limit = 100;
  total_pages = 1;
  total_results = 0;
  loading = true;
  listProducts: ProductModel[] = [];

  showCreateNewProduct = false;
  product_id_selected: string | null = null;

  async mounted() {
    await this.getListProducts();
  }

  async getListProducts() {
    this.product_id_selected = null;
    this.loading = true;
    const { products, total_results, total_pages } = await productsRepository.getListProducts({
      query: {},
      page: this.page,
      limit: this.limit,
    });
    this.listProducts = products;
    this.total_results = total_results ?? 0;
    this.total_pages = total_pages;
    this.loading = false;
  }

  async showEditorProduct(product_id: string) {
    this.product_id_selected = product_id;
    this.showCreateNewProduct = true;
  }

  async createNewOpenEditorProduct() {
    this.product_id_selected = null;
    this.showCreateNewProduct = true;
  }

  async closeEditorProduct() {
    this.showCreateNewProduct = false;
    this.product_id_selected = null;
  }
}
