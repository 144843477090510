
















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  props: {
    showAlertDeleteProduct: {
      required: true,
      default: false,
    },
  },
})
export default class ModalDeleteProduct extends Vue {
  @Prop({ type: String, default: null })
  product_id!: string;

  isLoading = false;

  async deleteProduct() {
    this.$emit('RELOAD');
    this.$emit('BACK');
  }
}
