
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductsRepository, { createNewProduct } from '@/repository/modules/products.repository';
import productsRepository from '@/repository/modules/products.repository';

interface SignsImpl {
  [key: string]: string;
}

interface FormImpl {
  [key: string]: any;
}

@Component({})
export default class ModalEditProduct extends Vue {
  @Prop({ type: String, default: null })
  product_id!: string;

  @Prop({ type: Boolean, default: false })
  showEditorProduct!: false;

  isLoading = false;
  form: FormImpl = {
    product_name: '',
    product_slug: '',
    product_country: '',
    product_currency: '',
    product_is_subscription: false,
    product_price: 0,
    product_price_trial: 0,
    product_price_subscription: 0,
    product_duration_trial: 0,
    product_duration_subscription: 0,
  };

  async mounted() {
    if (this.product_id) await this.loadData();
  }

  async loadData() {
    const product = await productsRepository.allDataProduct(this.product_id);
    this.form.product_name = product.product_name;
    this.form.product_slug = product.product_slug;
    this.form.product_country = product.product_country;
    this.form.product_currency = product.product_currency;
    this.form.product_price = product.product_price;
  }

  get IsValidForm() {
    if (this.form.product_name == '') return false;
    if (this.form.product_country == '') return false;
    if (this.form.product_currency == '') return false;
    if (this.form.product_is_subscription) {
      if (this.form.product_price_trial <= 0) return false;
      if (this.form.product_price_subscription <= 0) return false;
      if (this.form.product_duration_trial <= 0) return false;
      if (this.form.product_duration_subscription <= 0) return false;
    } else {
      if (this.form.product_price <= 0) return false;
    }
    return true;
  }

  sings: SignsImpl = { EUR: '€', USD: '$', GBP: '£' };

  async createProduct() {
    this.isLoading = true;
    this.cleanForm();
    const objProduct = this.createObject();
    console.log(objProduct);
    try {
      if (this.product_id) {
        await ProductsRepository.updateProduct(this.product_id, objProduct);
      } else {
        await ProductsRepository.createProduct(objProduct);
      }
      this.isLoading = false;
      this.$emit('RELOAD');
      this.$emit('BACK');
    } catch (e) {
      this.isLoading = false;
    }
  }

  cleanForm() {
    if (this.form.product_is_subscription) {
      this.form.product_price = 0;
    } else {
      this.form.product_price_trial = 0;
      this.form.product_price_subscription = 0;
      this.form.product_duration_trial = 0;
      this.form.product_duration_subscription = 0;
    }
  }

  createObject(): createNewProduct {
    return {
      name: this.form.product_name,
      slug: this.form.product_slug,
      price_subscription: +this.form.product_price_subscription,
      price_trial: +this.form.product_price_trial,
      price: +this.form.product_price,
      currency: this.form.product_currency,
      sign: this.sings[this.form.product_currency],
      country: this.form.product_country,
      billing_months: +this.form.product_duration_subscription,
      trial_days_duration: +this.form.product_duration_trial,
      is_subscription: this.form.product_is_subscription,
    };
  }

  setterPriceHint(price: number) {
    if (price == 0) return '1.00';
    return (price / 100).toFixed(2);
  }

  calculateIVA(price: number) {
    if (price == 0) return 'without IVA 0.0';
    const priceSinIva = price / 1.21;
    return (priceSinIva / 100).toFixed(2);
  }
}
