import API from '../repository';
import ProductModel, { ProductModelDTO } from '@/models/Product.model';

const RESOURCE = '/products';

export interface ResponseListProducts {
  result: ProductModelDTO[];
  total_results: number;
  total_pages: number;
  page: number;
  limit: number;
}

export type createNewProduct = {
  name: string;
  slug: string | null;
  price_subscription: number;
  price_trial: number;
  price: number;
  currency: string;
  sign: string;
  country: string;
  billing_months: number;
  trial_days_duration: number;
  is_subscription: boolean;
};

export default {
  async getListProducts({ query, page = 1, limit = 10 }: { query?: {} | null; page: number; limit: number }) {
    const result: ResponseListProducts = await API.get(`${RESOURCE}/list`, {
      params: {
        page,
        limit,
        ...query,
      },
    });
    return {
      products: result.result.map((c: ProductModelDTO) => new ProductModel(c)),
      total_results: result.total_results,
      total_pages: result.total_pages,
      page: result.page,
      limit: result.limit,
    };
  },

  async allDataProduct(product_id: string) {
    const product: ProductModelDTO = await API.get(`${RESOURCE}/${product_id}/full`);
    return new ProductModel(product);
  },

  async createProduct(newProduct: createNewProduct) {
    const product: ProductModelDTO = await API.post(`${RESOURCE}`, {
      ...newProduct,
    });
    return new ProductModel(product);
  },

  async updateProduct(product_id: string, newProduct: createNewProduct) {
    const { product }: { product: ProductModel } = await API.put(`${RESOURCE}/${product_id}`, {
      ...newProduct,
    });
    return {
      product,
    };
  },
};
