export interface ProductModelImpl {
  _id: string;
  product_name: string;
  product_price: number;
  product_currency: string;
  product_sign: string;
  product_country: string | null;
  product_slug: string;
}

export class ProductModelDTO implements ProductModelImpl {
  _id!: string;
  product_name!: string;
  product_price!: number;
  product_currency!: string;
  product_sign!: string;
  product_country!: string | null;
  product_slug!: string;
}

export default class ProductModel extends ProductModelDTO {
  constructor(dto: ProductModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getCountry = () => this.product_country ?? 'WW';

  getprice = () => `${this.generatePriceFormat((this.product_price / 100).toFixed(2))} ${this.product_currency}`;

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
